import type { cs } from "./cs"

export const pl: typeof cs = {
    translation: {
        general: {
            bookNow: 'Zarezerwuj',
            bookNowFor: 'Zarezerwuj za',
            czk: 'CZK',
            occupied: 'Garaż pełny',
            occupiedShort: 'Pełny',
            min: 'Min.',
            hours: 'godz.',
            hoursShortcut: 'godz.',
            list: 'Lista',
            map: 'Mapa',
            filters: 'Filtry',
            hide: 'Zamknij',
            garagesFound_one: 'Znaleziony <strong>{{count}} garaż</strong>',
            garagesFound_few: 'Znaleziono <strong>{{count}} garaże</strong>',
            garagesFound_many: 'Znaleziono <strong>{{count}} garaży</strong>',
            garagesFound_other: 'Znaleziono <strong>{{count}} garaży</strong>',
            garagesAvailableLater_one: 'Wygląda na to, że wiele garaży jest zarezerwowanych o tej porze. Ustaw czas rozpoczęcia rezerwacji na <strong>18:00</strong> a dostępny będzie jeszcze <strong>{{count}} garaż</strong>.',
            garagesAvailableLater_few: 'Wygląda na to, że wiele garaży jest zarezerwowanych o tej porze. Ustaw czas rozpoczęcia rezerwacji na <strong>18:00</strong> a dostępne będą jeszcze <strong>{{count}} garaże</strong>.',
            garagesAvailableLater_many: 'Wygląda na to, że wiele garaży jest zarezerwowanych o tej porze. Ustaw czas rozpoczęcia rezerwacji na <strong>18:00</strong> a dostępnych będzie jeszcze <strong>{{count}} garaży</strong>.',
            garagesAvailableLater_other: 'Wygląda na to, że wiele garaży jest zarezerwowanych o tej porze. Ustaw czas rozpoczęcia rezerwacji na <strong>18:00</strong> a dostępnych będzie jeszcze <strong>{{count}} garaży</strong>.',
            moveToEighteen: 'Ustaw czas rozpoczęcia na 18:00',
            close: 'Zamknij',
            availableFromEighteen: 'Dostępny od 18:00',
            fromEighteen: 'Od 18:00',
            lastPlacesAvailable: 'Ostatnie miejsca',
            boolean: {
                true: 'Tak',
                false: 'Nie'
            },
            phoneInput: {
                searchPlaceholder: 'Wybierz numer kierunkowy',
            }
        },
        filters: {
            showAvailable: 'Pokaż tylko dostępne garaże',
            yesShow: 'Tak',
            priceRange: 'Zakres cen',
            carHeight: 'Wysokość samochodu',
            sortBy: 'Sortuj według',
            distance: 'Odległość',
            price: 'Cena',
            remove: 'Anuluj wszystkie filtry',
            reset: 'Zresetuj filtry',
            showResults: 'Pokaż wyniki',
            noResultsMatch: 'Nie ma garaży pasujących do podanych parametrów.',
        },
        search: {
            reset: 'Resetowanie',
            edit: 'Edytuj',
            search: 'Szukaj',
            where: 'Gdzie chcesz zaparkować?',
            wherePlaceholder: 'Miasto, ulica, miejsce ...',
            whereEmpty: 'Brak sugestii',
            start: 'Początek',
            end: 'Koniec',
            reservationStart: 'Początek rezerwacji',
            reservationEnd: 'Koniec rezerwacji',
            reservationLength: 'Długość rezerwacji',
            reservationExtendedLength: '',
            reservationDate: 'Data rezerwacji',
            days_one: ' {{count}} dzień',
            days_few: ' {{count}} dni',
            days_many: ' {{count}} dni',
            days_other: ' {{count}} dni',
            hours_one: ' {{count}} godzina',
            hours_few: ' {{count}} godziny',
            hours_many: ' {{count}} godzin',
            hours_other: ' {{count}} godzin',
            minutes_one: 'minuta',
            minutes_few: 'minut',
            minutes_many: 'minut',
            minutes_other: 'minut',
            selectStart: 'Wybierz początek rezerwacji',
            selectEnd: 'Wybierz koniec rezerwacji',
            startInThePast: 'Początek rezerwacji to już przeszłość!',
            defaultLocation: 'Parking w Republice Czeskiej',
            extensionStartsTooLate: 'Wybrany początek przedłużenia nie może być późniejszy niż początek rezerwacji.',
            extensionEndsTooEarly: 'Wybrany koniec przedłużenia nie może być wcześniejszy niż koniec rezerwacji.',
            extensionIntoThePast: 'Rezerwacji nie można rozszerzyć na przeszłość.',
            checkAvailability: 'Sprawdź dostępność',
            sendExtensionRequest: 'Prześlij wniosek o przedłużenie',
            return: 'Powrót',
            extendReservation: 'Przedłuż rezerwację',
            extensionPossible: 'Rezerwacja może zostać przedłużona!',
            extensionPrice: 'Cena przedłużenia: {{price}} CZK',
            payToContinue: 'Aby potwierdzić przedłużenie, należy dopłacić różnicę.',
            toPaymentCTA: 'Przejdź do płatności',
        },
        routes: {
            garages: 'garaże',
            reservations: 'rezerwacje',
            reservations_steps: {
                reservation_time: 'czas-rezerwacji',
            },
            users: 'użytkownicy',
            signIn: 'zalogowanie',
            register: 'rejestracja',
            signOut: 'wylogowanie',
        },
        reservations: {
            status: {
                booked: 'Przyszłe',
                current: 'Aktywna',
                elapsed: 'Upłynęła',
                cancelled: 'Anulowana',
            },
            general: {
                active: 'Aktywne rezerwacje',
                all: 'Wszystkie rezerwacje',
                noBooked: 'Nie masz żadnych oczekujących ani przyszłych rezerwacji.',
                noBookedCreate: 'Utwórz rezerwację',
                remaining: 'Pozostała',
            },
            filters: {
                sortBy: 'Sortuj według',
                id: 'ID',
                startAt: 'Początek rezerwacji',
                status: 'Filtr statusu',
                all: 'Wszystko',
                active: 'Aktywny',
                elapsed: 'Upłynęły',
                cancelled: 'Anulowane',
            },
            table: {
                reservation: 'Reservation',
                garage: 'Garaż',
                unit: 'Miejsce',
                driver: 'Kierowca',
                licensePlate: 'Tablica rejestracyjna',
                phone: 'Telefon',
                date: 'Data',
                price: 'Cena',
                createdAt: 'Utworzony na',
                garageAndUnit: 'Garaż i miejsce',
                from: 'Od',
                to: 'To',
                phoneNumbers: 'Numery telefonów',
                cancelReservation: 'Anuluj rezerwację',
                editDetails: 'Edytuj płytę/telefon',
                bookNextDay: 'Powtórzenie rezerwacji następnego dnia',
                bookAgain: 'Zarezerwuj ten sam garaż',
                unpaidDifference: 'Zapłać {{price}} {{currency}}, aby sfinalizować przedłużenie.'
            },
            documents: {
                tickets: 'Bilety',
                invoices: 'Faktury',
                invoiceCorrections: 'Korekty faktur',
                creditNotes: 'Noty kredytowe',
                other: 'Inne dokumenty',
                termsOfService: 'Warunki korzystania z usług',
                privacyPolicy: 'Zasady ochrony danych osobowych',
                garageOperatingTerms: 'Regulamin garażu',
            },
            active: {
                open: 'Otwarty',
                entrance: 'Wjazd',
                exit: 'Wyjazd',
                reception: 'Recepcja',
                extend: 'Przedłużenie',
                payExtension: 'Zapłać przedłużenie',
                cancel: 'Anulowanie',
                confirm: 'Potwierdzić',
                ending: 'Kończenie',
                starting: 'Rozpoczęcie',
                ended: 'Zakończona',
            },
            edit: {
                save: 'Zapisz',
                cancel: 'Anuluj',
                phoneNumber1: 'Numer telefonu 1',
                phoneNumber2: 'Numer telefonu 2',
                licensePlate: 'Tablica rejestracyjna',
            },
            cancel: {
                confirmation: 'Czy naprawdę chcesz anulować tę rezerwację?',
                detail: 'Po anulowaniu rezerwacji nie będzie można jej odnowić.',
                cancel: 'Anuluj rezerwację',
                keep: 'Zachowaj rezerwację',
            },
            empty: {
                title: 'Nie dokonałeś jeszcze żadnej rezerwacji',
                text: 'Utwórz nową rezerwację. Będziesz wtedy mógł zobaczyć wszystkie swoje rezerwacje tutaj.'
            },
        },
        profile: {
            refetch: 'Załaduj ponownie',
            languageSelector: 'Wybór języka',
            currencySelector: 'Wybór waluty',
            signOut: 'Wyloguj się',
            userProfile: {
                title: 'Profil użytkownika',
                email: 'Adres e-mail',
                userName: 'Imię i nazwisko',
                phoneNumber1: 'Numer telefonu 1',
                phoneNumber1Tooltip: '',
                phoneNumber2: 'Numer telefonu 2',
                agreedToMarketing: 'Zgadzam się z marketingiem',
                edit_agreedToMarketing: 'Umożliwienie marketingu',
            },
            invoiceDetails: {
                title: 'Dane do faktury',
                edit_title: 'Edycja informacji o fakturach',
                edit_save: 'Edytuj',
                empty: 'Jeszcze nie dodano',
                invoice_title: 'Nazwa firmy',
                invoice_street: 'Ulic',
                invoice_city: 'Miasto',
                invoice_zip_code: 'Kod pocztowy',
                invoice_country: 'Kraj',
                invoice_ic: 'REGON',
                invoice_dic: 'NIP',
            },
            cars: {
                title: 'Samochody',
                edit_title: 'Modyfikować samochody',
                edit_save: 'Modyfikować',
                add_title: 'Dodaj samochód',
                add_confirm: 'Dodaj',
                name: 'Nazwa',
                numberPlate: 'WZP',
                color: 'Kolor',
                add: 'Dodaj'
            }

        },
        success: {
            congratulations: {
                title: 'Rezerwacja zakończyła się sukcesem! Dziękujemy.',
                imageAlt: 'Ilustracja rezerwacji',
                text: '"Na podany adres e-mail wysłaliśmy bilet parkingowy ze szczegółami przyjazdu na wybrane przez Ciebie miejsce parkingowe. W trosce o Twój komfort wysłaliśmy Ci również SMS na podany numer telefonu. Miejsce należy opuścić zaraz po skończeniu rezerwacji!"',
                myReservationsLink: 'Moje rezerwacje',
            },
            summary: {
                title: 'Szczegóły rezerwacji',
            },
            reservationSummary: {
                totalPrice: 'Ogółem:',
                email: 'Adres e-mail',
                name: 'Imię i nazwisko',
                numberPlate: 'WZP',
                carColor: 'Kolor pojazdu',
                floorWithNumber:'Pietro / Miejsce parkingowe',
                phoneNumber:'Numer telefonu 1',
                phoneNumber2:'Numer telefonu 2',
            }
        },
        garage: {
            car_dimensions: {
                title: 'Maksymalne wymiary',
                height: 'Wysokość',
                length: 'Długość',
                width: 'Szerokość',
            },
            showLess: 'Pokaż mniej',
            showMore: 'Pokaż więcej',
            mainBenefits: {
                first: 'Wielokrotne wjazdy i wyjazdy',
                second: 'Instrukcje w wiadomościach e-mail i SMS',
                third: 'Łatwe anulowanie i zmiana rezerwacji',
            },
            openOnMap: 'Nawigacja',
            benefits: {
                title: 'Zalety',
                list: {
                    '10_minutes_form_city_center': '10 min to centrum miasta',
                    '15_minutes_from_city_center': '15 min to centrum miasta',
                    '5_minutes_form_city_center': '5 min to centrum miasta',
                    call_reception: 'Zadzwoń do recepcji po przyjeździe.',
                    cctv: 'Monitoring',
                    cctv_at_entrance: 'Monitoring przy wjeździe do garażu',
                    city_center: 'Centrum miasta ',
                    historic_city_center: 'Zabytkowe centrum miasta',
                    charging_station: 'Ładowanie samochodów elektrycznych',
                    licence_plate_validation: 'Otwarcie szlabanu za pomocy numeru rejestracyjnego',
                    limited_vehicle_dimensions: 'Ograniczone wymiary pojazdu!',
                    main_reception: 'Dostęp do garażu można uzyskać w głównej recepcji w holu - tylko wtedy można wejść do garażu.',
                    nonstop_operation: 'Otwarte non stop',
                    nonstop_reception: 'Recepcja non stop',
                    nonstop_service: 'Otwarte non stop',
                    number_plate_recognition: 'Wjazd po rozpoznaniu numeru rejestracyjnego',
                    open_gate_by_attendant: 'Bramę garażu otwiera pracownik obsługi',
                    open_gate_by_phone: 'Bramę garażu otworzysz za pomocą telefonu',
                    parking_for_disabled: 'Monitoring przy wjeździe do garażu',
                    security_system: 'System zabezpieczeń',
                    subway_availability: 'Dobra dostępność tramwaju',
                    tram_availability: 'Dobra dostępność metra',
                    wc: 'WC',
                },
            },
            prohibitions: {
                title: 'Zakazy',
                list: {
                    lpg_cng_not_allowed: 'Samochody na LPG i CNG nie są dozwolone',
                    electric_car_not_allowed: 'Zakaz parkowania samochodów elektrycznych',
                    motorbikes_not_allowed: 'Zakaz parkowania motocykli',
                },
            },
            garage_videos: {
                title: 'Wideogaleria',
            },
            confirmationModal: {
                maxDimensionsTitle: 'Uwaga',
                cancel: 'Anuluj',
                complete: 'Zarezerwuj',
            }
        },
        loginCrossroads: {
            login: 'Zaloguj sie',
            createAccount: 'Załóż konto',
            skip: 'Teraz pomiń',
        },
        pwa: {
            search: 'Szukaj',
            myReservations: 'Moje rezerwacje',
            profile: 'Profil',
            myReservationsNoUser: 'Aby zarządzać rezerwacjami i otwartymi garażami, musisz się zalogować',
            profileNoUser: 'Aby zarządzać swoim profilem, pojazdami i rozliczeniami, należy się zalogować:'
        },
    },
}
