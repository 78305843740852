export const HEIGHT_POINTS = {
    "1": 1.6,
    "2": 1.9,
    "3": 2.1,
    "4": 2.3,
    "5": 2.6,
}

export const START_DATE = 'startDate'
export const END_DATE = 'endDate'

export const LANGUAGE_LOCALSTORAGE_KEY = 'userLanguage'
export const CURRENCY_LOCALSTORAGE_KEY = 'userCurrency'

// @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
export const API_KEY = import.meta.env.VITE_API_KEY
// @ts-ignore: Property 'env' does not exist on type 'ImportMeta'.
export const API_VERSION = import.meta.env.VITE_API_VERSION
