import { extendTheme } from '@mui/joy/styles'

const neutral = {
    50: '#F7F7F8',
    100: '#EBEBEF',
    200: '#D8D8DF',
    300: '#B9B9C6',
    400: '#8F8FA3',
    500: '#73738C',
    600: '#5A5A72',
    700: '#434356',
    800: '#25252D',
    900: '#131318',
}

declare module '@mui/joy/styles' {
    interface TypographySystemOverrides {
        'pwa-body-md': true
        'pwa-title-md': true
    }
}

// @ts-ignore
declare module '@mui/joy/Badge' {
    interface BadgePropsColorOverrides {
        secondary: true
    }
}

// @ts-ignore
declare module '@mui/joy/Button' {
    interface ButtonPropsColorOverrides {
        secondary: true
    }
}

export const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#f5f5f5',
                    100: '#e9e9e9',
                    200: '#d9d9d9',
                    300: '#c4c4c4',
                    400: '#9d9d9d',
                    500: '#7b7b7b',
                    600: '#555555',
                    700: '#434343',
                    800: '#262626',
                    900: '#000000',
                    solidDisabledBg: '#828282',
                    solidBg: 'var(--joy-palette-primary-900)',
                    solidActiveBg: 'var(--joy-palette-primary-900)',
                    solidHoverBg: 'var(--joy-palette-primary-800)',
                    solidHoverColor: 'var(--joy-palette-secondary-500)',
                    outlinedBorder: 'var(--joy-palette-primary-900)',
                    outlinedColor: 'var(--joy-palette-primary-900)',
                    outlinedActiveBg: 'var(--joy-palette-primary-100)',
                    softColor: 'var(--joy-palette-primary-900)',
                    softBg: 'var(--joy-palette-primary-100)',
                    softActiveBg: 'var(--joy-palette-primary-200)',
                    plainColor: 'var(--joy-palette-primary-900)',
                    plainActiveBg: 'var(--joy-palette-primary-300)',
                    plainHoverBg: 'var(--joy-palette-primary-200)',
                    plainHoverColor: 'var(--joy-palette-primary-900)',
                },
                //@ts-ignore
                secondary: {
                    50: '#fff8e1',
                    100: '#ffedb3',
                    200: '#ffe182',
                    300: '#ffd74f',
                    400: '#ffcc26',
                    500: '#ffc400',
                    600: '#ffb500',
                    700: '#ffa200',
                    800: '#ff9100',
                    900: '#ff7100',
                    solidBg: 'var(--joy-palette-secondary-500)',
                    solidActiveBg: 'var(--joy-palette-secondary-600)',
                    solidColor: 'var(--joy-palette-primary-900)',
                    solidHoverBg: 'var(--joy-palette-primary-900)',
                    solidHoverColor: 'var(--joy-palette-secondary-500)',
                    outlinedBorder: 'var(--joy-palette-secondary-500)',
                    outlinedColor: 'var(--joy-palette-secondary-700)',
                    outlinedActiveBg: 'var(--joy-palette-secondary-100)',
                    softColor: 'var(--joy-palette-secondary-800)',
                    softBg: 'var(--joy-palette-secondary-200)',
                    softActiveBg: 'var(--joy-palette-secondary-300)',
                    plainColor: 'var(--joy-palette-secondary-700)',
                    plainActiveBg: 'var(--joy-palette-secondary-100)',
                },
                neutral: {
                    ...neutral,
                    plainColor: 'var(--joy-palette-neutral-800)',
                    plainHoverColor: 'var(--joy-palette-neutral-900)',
                    plainHoverBg: 'var(--joy-palette-neutral-100)',
                    plainActiveBg: 'var(--joy-palette-neutral-200)',
                    plainDisabledColor: 'var(--joy-palette-neutral-300)',

                    outlinedColor: 'var(--joy-palette-neutral-800)',
                    outlinedBorder: 'var(--joy-palette-neutral-200)',
                    outlinedHoverColor: 'var(--joy-palette-neutral-900)',
                    outlinedHoverBg: 'var(--joy-palette-neutral-100)',
                    outlinedHoverBorder: 'var(--joy-palette-neutral-300)',
                    outlinedActiveBg: 'var(--joy-palette-neutral-200)',
                    outlinedDisabledColor: 'var(--joy-palette-neutral-300)',
                    outlinedDisabledBorder: 'var(--joy-palette-neutral-100)',

                    softColor: 'var(--joy-palette-neutral-800)',
                    softBg: 'var(--joy-palette-neutral-100)',
                    softHoverColor: 'var(--joy-palette-neutral-900)',
                    softHoverBg: 'var(--joy-palette-neutral-200)',
                    softActiveBg: 'var(--joy-palette-neutral-300)',
                    softDisabledColor: 'var(--joy-palette-neutral-300)',
                    softDisabledBg: 'var(--joy-palette-neutral-50)',
                    solidColor: 'var(--joy-palette-common-white)',
                    solidBg: 'var(--joy-palette-neutral-600)',
                    solidHoverBg: 'var(--joy-palette-neutral-700)',
                    solidActiveBg: 'var(--joy-palette-neutral-800)',
                    solidDisabledColor: 'var(--joy-palette-neutral-300)',
                    solidDisabledBg: 'var(--joy-palette-neutral-50)',
                },
                common: {
                    white: '#FFF',
                    black: '#09090D',
                },
                text: {
                    secondary: 'var(--joy-palette-neutral-600)',
                    tertiary: 'var(--joy-palette-neutral-500)',
                },
                background: {
                    body: 'var(--joy-palette-common-white)',
                    tooltip: 'var(--joy-palette-neutral-800)',
                    //backdrop: 'rgba(255 255 255 / 0.5)',
                },
                customLightYellow: {
                    light: '#FFFBE6',
                    main: '#FFE38D',
                    dark: '#FFCA28',
                },
                customGreen: {
                    main: '#3ea63e',
                },
                customBlack: {
                    main: '#0B0B0B',
                },
            },
        },
    },
    fontFamily: {
        display: '"Open Sans", var(--joy-fontFamily-fallback)',
        body: '"Open Sans", var(--joy-fontFamily-fallback)',
    },
    typography: {
        "pwa-body-md": {
            fontSize: '15px',
            fontWeight: '400',
        },
        "pwa-title-md": {
            fontSize: '0.875rem',
            fontWeight: '600',
        },
    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: '0.25rem',
                    ...(ownerState.color === 'primary' &&
                        ownerState.variant === 'outlined' && {
                            '&:hover': {
                                color: 'var(--joy-palette-primary-900)',
                                backgroundColor: 'var(--joy-palette-secondary-500)',
                                border: '1px solid var(--joy-palette-secondary-500)',
                            },
                        }),
                }),
            },
        },
        JoyBadge: {
            styleOverrides: {
                root: () => ({
                    '--Badge-ringSize': '0px',
                }),
            },
        },
        JoyModal: {
            styleOverrides: {
                backdrop: () => ({
                    backdropFilter: 'none',
                }),
            },
        },
        JoyModalClose: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: '0.25rem',
                    ...(ownerState.variant === 'outlined' && {
                        '&:hover': {
                            color: 'var(--joy-palette-primary-900)',
                            backgroundColor: 'var(--joy-palette-secondary-500)',
                            border: '1px solid var(--joy-palette-secondary-500)',
                            '& svg': {
                                fill: 'var(--joy-palette-primary-900)',
                            },
                        },
                    }),
                    ...(ownerState.variant === 'plain' && {
                        color: 'var(--joy-palette-secondary-500)',
                        '& svg': {
                            fill: 'var(--joy-palette-secondary-500)',
                        },
                        '&:hover': {
                            color: 'var(--joy-palette-primary-900)',
                            backgroundColor: 'var(--joy-palette-secondary-500)',
                            border: '1px solid var(--joy-palette-secondary-500)',
                            '& svg': {
                                fill: 'var(--joy-palette-primary-900)',
                            },
                        },
                        '&:focus': {
                            border: '1px solid var(--joy-palette-secondary-500)',
                        },
                    }),
                }),
            },
        },
        JoyTab: {
            styleOverrides: {
                root: () => ({
                    '&.Mui-selected': {
                        backgroundColor: 'var(--joy-palette-secondary-500)',
                    },
                }),
            },
        },
    },
})
